.modal {
  //background-color: red;
  opacity: 0;
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  pointer-events: none;
  padding: 4.5rem 0;

  @include respond-to('small') {
    padding: 1rem;
  }

  &-aspect-ratio {
    //content: " ";
    display: block;
    position: relative;
    padding-bottom: 56.25%;
    //background-color: rgba(0,0,0,0);
    transition: background-color 500ms;
    //background-color: $orange;
  }

  &-embed {
    width: 100%;
    max-height: calc(100vh - 2rem);
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    transform: translate3d(0,100px,0);
    opacity: 0;
    margin: 0 auto;
    //margin-top: 4rem;
    transition: transform 600ms, opacity 200ms;
    overflow: hidden;

    img {
      position: absolute;
      z-index: 5;
      height: 100%;
      width: auto;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      object-fit: cover;
      filter: blur(15px);
      /*width: 100%;
      object-fit: contain;
      top: 0;
      left: 0;
      overflow: hidden;*/
    }

    iframe {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-text {
    margin-top: 2rem;
    margin-bottom: 1rem;

    strong {
      font-weight: 500;
      margin-top: 0.5rem;
      display: inline-block;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    .modal-year {
      margin-bottom: 1rem;
    }
  }

  &.is-visible {
    opacity: 1;
    z-index: 1000;
    pointer-events: auto;

    .modal-embed {
      transform: translate3d(0,0,0);
      opacity: 1;

      &:before {
        //background-color: rgba(0,0,0,1);
      }
    }
  }
}



.modal-close {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 9000;
  cursor: pointer;
  width: 48px;
  height: 48px;
  transform: translate3d(0,0,0);
  opacity: 0;
  transition: transform 200ms;
  filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.6));

  &:hover {
    transform: scale(1.1) translate3d(0,0,0);
  }
}
