.footer {
  margin-top: 4rem;
  padding: 1rem;
  background-color: rgb(19, 19, 19);
  color: white;

  &-content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
  }

  &-links {
    a {
      margin-left: 1rem;
    }
  }
}
