//@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap');

/**
 * Fonts
*/

// @font-face {
//   font-family: 'DINP-R';
//   src: url("../fonts/DINPro-Regular.eot?") format("eot"),
//   url("../fonts/DINPro-Regular.woff") format("woff"),
//   url("../fonts/DINPro-Regular.ttf") format("truetype"),
//   url("../fonts/DINPro-Regular.svg#DINPro-Regular") format("svg");
//   font-style: normal;
// }



// @font-face {
//   font-family: 'Protest';
//   src: url("../fonts/Protest-BookMono.eot?") format("eot"),
//   url("../fonts/Protest-BookMono.woff") format("woff"),
//   url("../fonts/Protest-BookMono.ttf") format("truetype");
//   font-style: normal;
// }
