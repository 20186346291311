.button {
  display: inline-block;
  position: relative;
  border-radius: 20px;
  background-color: $white;
  color: $black;
  padding: 0.5rem 1.5rem;
  margin: 0.2rem 0.5rem 0 0.5rem;

  &:visited {
    color: $black;
  }

  &.secondary {
    background-color: transparent;
    border: 2px solid $white;
    color: $white;
  }
}
