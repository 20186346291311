/* By Athlon Front-End Team */

/* Core variables */
@import 'base/vars';

/* CSS Reset */
@import 'base/reset';

/* CSS print */
@import 'base/print';

/* Fonts */
@import 'base/fonts';

/* Mixins, helpers and functions */
@import 'base/mixins';

/* Vendor */

/* Components */
@import '../components/header/header';
@import '../components/footer/footer';
@import '../components/button/button';
@import '../components/modal/modal';
@import '../components/intro/intro';
@import '../components/project-grid/project-grid';
@import '../components/project-teaser/project-teaser';
@import '../components/contact/contact';
@import '../components/legal/legal';

/* Browser specific styles */
@import 'dirty/hacks';

/* Demo styles */


body {
  background: rgb(12, 12, 12);
  color: white;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  padding: 0;

  @include respond-to(medium) {
    font-size: 18px;
  }
}

.container {
  transition: filter 500ms, opacity 800ms;

  &.blur {
    filter: blur(40px);
    opacity: 0.3;
    pointer-events: none;
  }
}

* {
  &::selection {
    color: white;
    background: $orange;
  }
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 5rem;
}

h2 {
  font-size: 1.2rem;
  font-weight: bold;
}

img, figure {
  width: 100%;
  display: inherit;
}

.video,
.img {
  position: relative;
  display: block;
  --w: 1;
  --h: 1;
  padding-bottom: calc(100% / var(--w) * var(--h));
  background: var(--color-black);
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

main {
  padding: 1rem;
  min-height: 100vh;
}

.grid {
  --columns: 12;
  --gutter: 3rem;
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;
}

.grid > .column {
  margin-bottom: var(--gutter);
}

.autogrid {
  --gutter: 3rem;
  --min: 10rem;
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(auto-fit, minmax(var(--min), 1fr));
  grid-auto-flow: dense;
}

@media screen and (min-width: 60rem) {
  body {
    --padding: 3rem;
  }

  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
  .grid > .column {
    grid-column: span var(--columns);
  }

}
