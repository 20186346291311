.intro {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  //position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 2px solid white;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include respond-to('medium') {
    flex-wrap: nowrap;
  }

  @include respond-to('medium') {
    margin-top: 1rem;
    background: rgb(12, 12, 12);
    position: sticky;
    top: 0;
  }

  h1 {
    margin: 0;
    font-size: 3rem;
    display: block;
    font-weight: 500;

    @include respond-to('medium') {
      margin-right: 1rem;
    }
  }

  p {
    letter-spacing: 0.03rem;
    margin-bottom: 1rem;

    @include respond-to('medium') {
      margin-bottom: 0;
    }
  }

  .intro-item {
    text-align: left;
    //width: 100%;
    display: flex;
    //flex-direction: column;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include respond-to('medium') {
      justify-content: flex-end;
      align-items: center;
      //width: calc(33.333% - 0.5rem);
      flex-direction: row;
    }

    &--left {
      flex-direction: column;
      //align-items: flex-start;
      text-align: center;

      @include respond-to('medium') {
        text-align: left;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .contact-portrait {
      width: 55px;
      height: 55px;
      margin: 0;
      margin-right: 1rem;

      @include respond-to('medium') {
        margin-right: 0;
        margin-left: 1rem;
      }
    }
  }

  .intro-images {
    display: flex;
  }
}
