.project-teaser {
  color: white;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  position: relative;
  background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;

  &:hover {
    img {
      transform: scale(1.03);
    }
  }

  @include respond-to('small') {
    width: calc(50% - 0.5rem);
  }

  &:after {
    content: " ";
    display: block;
    position: relative;
    padding-bottom: 56.25%;
  }

  h3 {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    padding: 0.5rem;
  }

  svg {
    margin-right: 10px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    z-index: -1;
    height: 100%;
    transition: transform 1000ms;
  }

  &_embed {
    display: none;
  }
}
