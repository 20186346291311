.contact {
  margin-top: 4rem;
  margin-bottom: 4rem;

  @include respond-to('medium') {
    display: flex;
    justify-content: center;
  }

  &-profile {
    margin: 2rem;
  }

  &-portrait {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 1rem auto;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}
