@import "https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary, main {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

body {
  margin: 0;
}

ul {
  list-style-type: none;
}

a {
  background: none;
  text-decoration: none;
}

button, input {
  line-height: normal;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: none;
  padding: 0;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

textarea:focus, input:focus {
  outline: none;
}

input {
  appearance: none;
  margin: 0;
  padding: 0;
}

@media print {
  @page {
    margin: 1cm;
  }

  body {
    color: #000;
    background: #fff;
  }

  a, blockquote, table, pre {
    page-break-inside: avoid;
  }

  ul, ol, dl {
    page-break-before: avoid;
  }
}

.header {
  z-index: 1000;
  background-color: #fffc;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header .logo {
  width: 2rem;
}

.header .menu a {
  text-decoration: underline;
}

.footer {
  color: #fff;
  background-color: #131313;
  margin-top: 4rem;
  padding: 1rem;
}

.footer-content {
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

.footer-links a {
  margin-left: 1rem;
}

.button {
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  margin: .2rem .5rem 0;
  padding: .5rem 1.5rem;
  display: inline-block;
  position: relative;
}

.button:visited {
  color: #000;
}

.button.secondary {
  color: #fff;
  background-color: #0000;
  border: 2px solid #fff;
}

.modal {
  opacity: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding: 4.5rem 0;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (min-width: 480px) {
  .modal {
    padding: 1rem;
  }
}

.modal-aspect-ratio {
  padding-bottom: 56.25%;
  transition: background-color .5s;
  display: block;
  position: relative;
}

.modal-embed {
  width: 100%;
  max-height: calc(100vh - 2rem);
  opacity: 0;
  border-radius: 4px;
  margin: 0 auto;
  transition: transform .6s, opacity .2s;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 100px, 0);
}

.modal-embed img {
  z-index: 5;
  height: 100%;
  width: auto;
  object-fit: cover;
  filter: blur(15px);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.modal-embed iframe {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-text {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.modal-text strong {
  margin-top: .5rem;
  font-weight: 500;
  display: inline-block;
}

.modal-text h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.modal-text .modal-year {
  margin-bottom: 1rem;
}

.modal.is-visible {
  opacity: 1;
  z-index: 1000;
  pointer-events: auto;
}

.modal.is-visible .modal-embed {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.modal-close {
  z-index: 9000;
  cursor: pointer;
  width: 48px;
  height: 48px;
  opacity: 0;
  filter: drop-shadow(0 0 4px #0009);
  transition: transform .2s;
  position: fixed;
  top: 1rem;
  right: 1rem;
  transform: translate3d(0, 0, 0);
}

.modal-close:hover {
  transform: scale(1.1)translate3d(0, 0, 0);
}

.intro {
  width: 100%;
  max-width: 1200px;
  z-index: 100;
  border-bottom: 2px solid #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  top: 0;
}

@media (min-width: 720px) {
  .intro {
    flex-wrap: nowrap;
  }
}

@media (min-width: 720px) {
  .intro {
    background: #0c0c0c;
    margin-top: 1rem;
    position: sticky;
    top: 0;
  }
}

.intro h1 {
  margin: 0;
  font-size: 3rem;
  font-weight: 500;
  display: block;
}

@media (min-width: 720px) {
  .intro h1 {
    margin-right: 1rem;
  }
}

.intro p {
  letter-spacing: .03rem;
  margin-bottom: 1rem;
}

@media (min-width: 720px) {
  .intro p {
    margin-bottom: 0;
  }
}

.intro .intro-item {
  text-align: left;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 720px) {
  .intro .intro-item {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.intro .intro-item--left {
  text-align: center;
  flex-direction: column;
}

@media (min-width: 720px) {
  .intro .intro-item--left {
    text-align: left;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.intro .intro-item .contact-portrait {
  width: 55px;
  height: 55px;
  margin: 0 1rem 0 0;
}

@media (min-width: 720px) {
  .intro .intro-item .contact-portrait {
    margin-left: 1rem;
    margin-right: 0;
  }
}

.intro .intro-images {
  display: flex;
}

.project-grid {
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

.project-teaser {
  color: #fff;
  width: 100%;
  cursor: pointer;
  background: linear-gradient(0deg, #0009 0%, #0000 35% 100%);
  border: 1px solid #ffffff1a;
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
}

.project-teaser:hover img {
  transform: scale(1.03);
}

@media (min-width: 480px) {
  .project-teaser {
    width: calc(50% - .5rem);
  }
}

.project-teaser:after {
  content: " ";
  padding-bottom: 56.25%;
  display: block;
  position: relative;
}

.project-teaser h3 {
  z-index: 200;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.project-teaser svg {
  margin-right: 10px;
}

.project-teaser img {
  object-fit: cover;
  z-index: -1;
  height: 100%;
  transition: transform 1s;
  position: absolute;
  inset: 0;
}

.project-teaser_embed {
  display: none;
}

.contact {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (min-width: 720px) {
  .contact {
    justify-content: center;
    display: flex;
  }
}

.contact-profile {
  margin: 2rem;
}

.contact-portrait {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 1px solid #ffffff1a;
  border-radius: 50%;
  margin: 1rem auto;
}

.legal-text {
  display: blocj;
  width: 100%;
  max-width: 1200px;
  text-align: left;
  margin: 0 auto;
}

.legal-text h1 {
  margin-bottom: 1rem;
}

.legal-text h2 {
  margin-bottom: .5rem;
}

.legal-text h1, .legal-text h2, .legal-text h3 {
  font-weight: 500;
}

.legal-text p {
  letter-spacing: .1rem;
  margin-bottom: 1rem;
}

body {
  color: #fff;
  text-align: center;
  background: #0c0c0c;
  padding: 0;
  font-family: Oswald, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

@media (min-width: 720px) {
  body {
    font-size: 18px;
  }
}

.container {
  transition: filter .5s, opacity .8s;
}

.container.blur {
  filter: blur(40px);
  opacity: .3;
  pointer-events: none;
}

::selection {
  color: #fff;
  background: #e86830;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

h1 {
  margin-top: 5rem;
  font-size: 2rem;
  font-weight: bold;
}

h2 {
  font-size: 1.2rem;
  font-weight: bold;
}

img, figure {
  width: 100%;
  display: inherit;
}

.video, .img {
  --w: 1;
  --h: 1;
  padding-bottom: calc(100% / var(--w)  * var(--h) );
  background: var(--color-black);
  display: block;
  position: relative;
}

.container {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

main {
  min-height: 100vh;
  padding: 1rem;
}

.grid {
  --columns: 12;
  --gutter: 3rem;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;
  display: grid;
}

.grid > .column {
  margin-bottom: var(--gutter);
}

.autogrid {
  --gutter: 3rem;
  --min: 10rem;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(auto-fit, minmax(var(--min), 1fr) );
  grid-auto-flow: dense;
  display: grid;
}

@media screen and (min-width: 60rem) {
  body {
    --padding: 3rem;
  }

  .grid {
    grid-template-columns: repeat(12, 1fr);
  }

  .grid > .column {
    grid-column: span var(--columns);
  }
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/*# sourceMappingURL=app.css.map */
