.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;

  .logo {
    width: 2rem;
  }

  .menu {
    a {
      text-decoration: underline;
    }
  }
}
