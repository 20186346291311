.legal-text {
  display: blocj;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;

  h1 {
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 0.5rem;
  }

  h1, h2, h3 {
    font-weight: 500;
  }

  p {
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
  }
}
