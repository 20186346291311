/**
 * Variables
*/

/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
$black: #000;
$white: #fff;
$blue: #3663DC;
$dark-blue: #0F2256;
$orange: #e86830;


$article-max-width: 700px;

$body-background-color: #333;
$foreground-color: #fff;
/* Components */

$breakpoints: (
  small: 480px,
  medium: 720px,
  large: 960px,
  wide: 1200px
);

